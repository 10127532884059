<template>
  <div class="btn-request-design" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
    aria-controls="offcanvasRight">
    <button> {{ $t('request') }}</button>
  </div>

  <div class="offcanvas flex-row offcanvas-end w-100" tabindex="-1" id="offcanvasRight"
    aria-labelledby="offcanvasRightLabel">
    <div class="design-request-from">
      <div class="offcanvas-header justify-content-end">
        <div type="button" class="" data-bs-dismiss="offcanvas" aria-label="Close">
          <svg>
            <use href="#btn-close"></use>
          </svg>
        </div>
      </div>

      <div class="offcanvas-body px-sm-5 py-0">
        <div>
          <h2>{{ $t('designRequest_1') }}</h2>
          <p>
            {{ $t('designRequest_2') }}
          </p>
          <p>
            {{ $t('designRequest_3') }}
          </p>
        </div>

        <div class="form-area py-4">
          <div>
            <input v-model="formData.companyName" type="text" :placeholder="$t('designForm_1')" />
          </div>
          <div>
            <input v-model="formData.website" type="text" :placeholder="$t('designForm_2')" />
          </div>
          <div>
            <input v-model="formData.email" type="email" :placeholder="$t('designForm_3')" />
          </div>
          <div>
            <textarea v-model="formData.projectDescription" :placeholder="$t('designForm_4')"></textarea>
          </div>
          <div>
            <input v-model="formData.number" type="number" :placeholder="$t('designForm_5')" />
          </div>
          <div>
            <input v-model="formData.country" type="text" :placeholder="$t('designForm_6')" />
          </div>
          <div>
            <input v-model="formData.name" type="text" :placeholder="$t('designForm_7')" />
          </div>
          <div>
            <button @click="getFormData" class="btn-submit"> {{ $t('designForm_8') }}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="banner-section d-none d-lg-flex">
      <div class="banner-image"></div>
    </div>
  </div>
  <div hidden>
    <svg id="btn-close" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96" fill="">
      <g clip-path="url(#clip0_15_76)">
        <path
          d="M76 25.64L70.36 20L48 42.36L25.64 20L20 25.64L42.36 48L20 70.36L25.64 76L48 53.64L70.36 76L76 70.36L53.64 48L76 25.64Z"
          fill="" />
      </g>
      <defs>
        <clipPath id="clip0_15_76">
          <rect width="96" height="96" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { collection, addDoc } from "firebase/firestore";
import comp from "../comp.json";

export default {
  data() {
    return {
      data: 'Studio',
      comp: comp,
      formData: {
        companyName: "",
        website: "",
        email: "",
        projectDescription: "",
        country: "",
        number: "",
        name: "",
      },
    };
  },
  methods: {
    async getFormData() {
      try {
        const formData = {
          email: this.formData.email,
          website: this.formData.website,
          companyName: this.formData.companyName,
          projectDescription: this.formData.projectDescription,
          phoneNumber: this.formData.number.toString(),
          country: this.formData.country,
          name: this.formData.name,
        };

        // Отправка данных в Firebase
        await addDoc(collection(db, "clients"), formData);

        // Отправка уведомления в Telegram
        await this.sendTelegramNotification(formData);

        alert(this.$t('success_msg'));

        // Очистка формы
        this.formData = {
          companyName: "",
          website: "",
          email: "",
          projectDescription: "",
          country: "",
          number: "",
          name: "",
        };
      } catch (error) {
        console.error(this.$t('error_msg'), error);
        alert(this.$t('error_msg_text'));
      }
    },

    async sendTelegramNotification(formData) {
      const botToken = process.env.VUE_APP_BOT_TOKEN;
      const chatId = process.env.VUE_APP_CHAT_ID;

      const message = `
      🆕 Design request
      📛 Name: ${formData.name}
      🏢 Company name: ${formData.companyName}
      🌐 Website: ${formData.website}
      📧 Email: ${formData.email}
      📞 Phone number: ${formData.phoneNumber}
      🌍 Country: ${formData.country}
      📝 Project Description: ${formData.projectDescription}
    `;

      try {
        const url = `https://api.telegram.org/bot${botToken}/sendMessage`;
        await fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            chat_id: chatId,
            text: message,
          }),
        });
      } catch (error) {
        console.error("Ошибка отправки уведомления в Telegram:", error);
      }
    },
  },
};
</script>