<template>
  <div class="about-page">
    <h2 class="text-center mt-3">About</h2>
    <div class="d-flex align-items-center justify-content-center px-5 py-3">
      <div class="row align-items-center justify-content-center h-100">
        <div class="col-lg-6">
          <p>{{ $t('about_1') }}</p>
          <p>{{ $t('about_2') }}</p>
          <p>{{ $t('about_3') }}</p>
        </div>
      </div>
    </div>
    <div class="py-5 px-5">
      <h1 style="text-align: center">{{ $t('service') }}</h1>
    </div>
    <div class="col-lg-8 mx-auto">
      <div class="row py-4 px-4">
        <!-- Service sections here -->
        <div class="col-sm-6 py-3 px-3">
          <div>
            <h4 style="color: #00195E"> 01 | {{ $t('service_1') }}
            </h4>
            <h6 class="black py-3"> {{ $t('service_2') }} </h6>
          </div>
        </div>

        <div class="col-sm-6 py-3 px-3">
          <div>
            <h4 style="color: #00195E">
              02 | {{ $t('service_3') }}
            </h4>
            <h6 class="black py-3"> {{ $t('service_4') }} </h6>
          </div>
        </div>

        <div class="col-sm-6 py-3 px-3">
          <div>
            <h4 style="color: #00195E">
              03 | {{ $t('service_5') }}
            </h4>
            <h6 class="black py-3"> {{ $t('service_6') }} </h6>
          </div>
        </div>

        <div class="col-sm-6 py-3 px-3">
          <div>
            <h4 style="color: #00195E">
              04 | {{ $t('service_7') }}
            </h4>
            <h6 class="black py-3"> {{ $t('service_8') }} </h6>
          </div>
        </div>

        <div class="col-sm-6 py-3 px-3">
          <div>
            <h4 style="color: #00195E">
              05 | {{ $t('service_9') }}
            </h4>
            <h6 class="black py-3"> {{ $t('service_10') }} </h6>
          </div>
        </div>

        <div class="col-sm-6 py-3 px-3">
          <div>
            <h4 style="color: #00195E">
              06 | {{ $t('service_11') }}
            </h4>
            <h6 class="black py-3"> {{ $t('service_12') }} </h6>
          </div>
        </div>

      </div>
    </div>
    <div class="px-4 py-4">
      <section class="form-area">
        <div class="contact-form">
          <h2 style="text-align: center;"> {{ $t('request_design') }} </h2>
          <p> {{ $t('request_design_text') }}</p>
          <form @submit.prevent="getFormData">
            <div class="form-input">
              <input v-model="formData.companyName" type="text" :placeholder="$t('designForm_1')" />
            </div>
            <div>
              <input v-model="formData.email" type="email" :placeholder="$t('designForm_3')" />
            </div>
            <div>
              <input v-model="formData.projectDescription" type="text" :placeholder="$t('designForm_4')" />
            </div>
            <div>
              <input v-model="formData.number" type="number" :placeholder="$t('designForm_5')" />
            </div>
            <div>
              <input v-model="formData.name" type="text" :placeholder="$t('designForm_7')" />
            </div>
            <div>
              <button type="submit" class="btn-submit">{{ $t('designForm_8') }}</button>
            </div>
          </form>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { collection, addDoc } from "firebase/firestore";
import comp from "../comp.json";

export default {
  data() {
    return {
      data: 'Studio',
      comp: comp,
      formData: {
        companyName: "",
        email: "",
        projectDescription: "",
        number: "",
        name: "",
      },
    };
  },
  methods: {
    async getFormData() {
      try {
        const clientData = {
          email: this.formData.email,
          companyName: this.formData.companyName,
          projectDescription: this.formData.projectDescription,
          phoneNumber: this.formData.number.toString(),
          name: this.formData.name,
        };

        // Отправка данных в Firebase
        await addDoc(collection(db, "clients"), {
          ...clientData,
        });

        // Отправка уведомления в Telegram
        await this.sendTelegramNotification(clientData);

        alert(this.$t('success_msg'));

        // Очистка формы
        this.formData = {
          companyName: "",
          email: "",
          projectDescription: "",
          number: "",
          name: "",
        };
      } catch (error) {
        console.error(this.$t('error_msg'), error);
        alert(this.$t('error_msg_text'));
      }
    },

    async sendTelegramNotification(clientData) {
      const botToken = process.env.VUE_APP_BOT_TOKEN;
      const chatId = process.env.VUE_APP_CHAT_ID;

      const message = `
      🆕 New design request (about)
      📛 Name: ${clientData.name}
      🏢 Company name: ${clientData.companyName}
      📧 Email: ${clientData.email}
      📞 Phone number: ${clientData.phoneNumber}
      📝 Project description: ${clientData.projectDescription}
    `;

      try {
        const url = `https://api.telegram.org/bot${botToken}/sendMessage`;
        await fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            chat_id: chatId,
            text: message,
          }),
        });
      } catch (error) {
        console.error("Ошибка отправки уведомления в Telegram:", error);
      }
    },
  },
}
</script>