<template>
  <div class="fade-in">
    <div class="contact-page">
      <div class="container h-100">
        <div class="row justify-content-center h-100">
          <div class="col-lg-8">
            <p style="font-size: 24px;"> {{ $t('contact_info') }} </p>
            <p>
              <a href="mailto:contact@artonion.ro" style="color: #313131;"> contact@artonion.ro </a>
            </p>
            <p>
              <a href="tel:+40753398167" style="color: #313131;"> +40 (753) 398 167 </a>
            </p>
            <p>
              <a href="https://instagram.com/artonion.ro?igshid=YmMyMTA2M2Y=" style="color: #313131" target="_blank">
                Instagram </a> | <a href="http://wa.me/753398167" style="color: #313131" target="_blank">WhatsApp</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>